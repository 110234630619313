const DefaultPropertyGroupThumbnail = ({ backgroundColor, logoColor }: any) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 240 132" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
      <rect width="240" height="132" fill={backgroundColor} />
      <path
        d="M131.167 76C132.521 76 133.667 74.9062 133.667 73.5V58.5C133.667 57.1458 132.521 56 131.167 56H109.5C108.094 56 107 57.1458 107 58.5V73.5C107 74.9062 108.094 76 109.5 76H131.167ZM112.833 58.9167C114.396 58.9167 115.75 60.2708 115.75 61.8333C115.75 63.4479 114.396 64.75 112.833 64.75C111.219 64.75 109.917 63.4479 109.917 61.8333C109.917 60.2708 111.219 58.9167 112.833 58.9167ZM110.333 72.6667V70.1667L114.031 66.4687C114.292 66.2083 114.656 66.2083 114.917 66.4687L117.833 69.3333L124.865 62.3021C125.125 62.0417 125.49 62.0417 125.75 62.3021L130.333 66.8333V72.6667H110.333Z"
        fill={logoColor}
      />
    </svg>
  );
};

export default DefaultPropertyGroupThumbnail;
