import Image from 'next/image';
import DefaultPropertyGroupThumbnail from 'assets/default-property-group-thumbnail.svg';
import { useState } from 'react';

interface PropertyImageThumbnail {
  src: string;
  alt?: string;
  backgroundColor: string;
  logoColor: string;
}

const PropertyImageThumbnail = ({ src, alt, backgroundColor, logoColor }: PropertyImageThumbnail) => {
  const [useDefaultImage, setUseDefaultImage] = useState(false);

  if (useDefaultImage || !src) {
    return <DefaultPropertyGroupThumbnail backgroundColor={backgroundColor} logoColor={logoColor} />;
  }

  return <Image onError={() => setUseDefaultImage(true)} src={src} layout="fill" objectFit="cover" alt={alt} />;
};

export default PropertyImageThumbnail;
