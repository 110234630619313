import styled from 'styled-components';

export const OverviewWrapper = styled.div`
  margin-bottom: 64px;
`;

export const OverviewHeader = styled.div<{ propstepManaged: boolean }>`
  margin-bottom: 16px;
  border-radius: 8px;
  color: ${(props) =>
    props.propstepManaged
      ? props.theme.color.propertyGroupOverviewHeaderTextColorPropstepManaged
      : props.theme.color.propertyGroupOverviewHeaderTextColor};
  background: ${(props) =>
    props.propstepManaged
      ? props.theme.color.propertyGroupOverviewHeaderBackgroundColorPropstepManaged
      : props.theme.color.propertyGroupOverviewHeaderBackgroundColor};

  > div > div {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: flex;
  }
`;

export const OverviewHeaderImage = styled.div`
  flex: 0 0 280px;
  width: 280px;
  min-height: 164px;
  position: relative;
  &:hover {
    opacity: 0.9;
  }
`;

export const OverviewHeaderImageInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  top: 0;
  left: 0;
`;

export const OverviewHeaderImageInnerLabel = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  p {
    background: #ffed48;
    color: ${(props) => props.theme.color.secondaryTextColor};
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 600;
  }
`;

export const OverviewHeaderDetails = styled.div`
  flex: 1 1 auto;
  padding: 24px 24px 16px 16px;
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 24px 24px 16px 24px;
  }
  h2 {
    word-break: break-word;
    hyphens: auto;
    margin-bottom: 12px;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const OverviewHeaderDetailsList = styled.ul`
  display: flex;
  margin-bottom: 28px;
  li {
    a,
    span {
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: none;
      svg {
        margin-left: 8px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      margin-right: 32px;
      svg {
        margin-right: 8px;
      }
    }
  }
`;

export const OverviewHeaderDetailsCompany = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > div {
    &:nth-of-type(1) {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      img {
        border-radius: 100%;
      }
    }
    &:nth-of-type(2) {
      flex: 1 1 auto;
    }
  }
  &:hover {
    > div {
      &:nth-of-type(2) {
        text-decoration: underline;
      }
    }
  }
`;

export const OverviewHeaderDetailsManaged = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin-top: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  > div {
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      height: 16px;
      margin-left: 4px;
    }
  }
`;

export const OverviewProperties = styled.div`
  border: 1px solid ${(props) => props.theme.color.propertyGroupOverviewBorderColor};
  border-radius: 8px;
  overflow: hidden;
`;

export const OverviewPropertiesList = styled.ul`
  li {
    color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColor};
    background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid ${(props) => props.theme.color.propertyGroupOverviewBorderColor};
    cursor: pointer;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      flex-direction: row;
    }
    &:only-child,
    &:last-child {
      border-bottom: 0;
    }
    > div {
      &:nth-of-type(1) {
        flex: 1 1 auto;
        min-width: 0;
        padding-right: 16px;
        @media only screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
          margin-bottom: 4px;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-of-type(2) {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
          flex-wrap: nowrap;
        }
        > div {
          margin-right: 24px;
          &:last-of-type {
            margin-right: 0;
            @media only screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
              flex: 0 0 100%;
              margin-top: 8px;
            }
          }
          svg {
            margin-right: 4px;
          }
        }
      }
    }

    &:hover {
      color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColorHover};
      background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColorHover};
    }

    &:active {
      color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColorActive};
      background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColorActive};
    }
  }
`;

export const OverviewPropertiesMore = styled.div`
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColor};
  border-top: 1px solid ${(props) => props.theme.color.propertyGroupOverviewBorderColor};
  background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColor};
  > a {
    padding: 20px;
    display: block;
  }
  &:hover {
    color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColorHover};
    background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColorHover};
  }
  &:active {
    color: ${(props) => props.theme.color.propertyGroupOverviewListItemTextColorActive};
    background: ${(props) => props.theme.color.propertyGroupOverviewListItemBackgroundColorActive};
  }
  svg {
    margin-left: 4px;
  }
`;

export const OverviewPropertiesEmpty = styled.div`
  text-align: center;
  padding: 24px;
`;
